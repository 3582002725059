/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/logo.png'
import { Link } from "react-router-dom";

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                    <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
                        <center>
                            <img width={75} height={75} src={Logo} alt="" />
                            <h3>Liger.fashion</h3>
                        </center>

                    </div>
                    <section className="mt-60 mb-60">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="product-detail accordion-detail">
                                        <div className="row mb-50">
                                            <div className="col-md-6 col-sm-12 col-xs-12">


                                                <div className="single-social-share clearfix mt-50 mb-15">
                                                    <div className="mobile-social-icon wow fadeIn  mb-sm-5 mb-md-0 animated">
                                                        <a className="facebook" href="https://www.facebook.com/ligerfashionfootwear"><i className="fab fa-facebook-f"></i></a>

                                                        <a className="instagram" href="https://www.instagram.com/Liger.fashion/"><i className="fab fa-instagram"></i></a>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12" style={{ textAlign: "right" }} >
                                                <div className="detail-info" >

                                                    <div style={{ width: '100%', disply: 'flex', justfyContent: 'flex-end' }}>
                                                        <div className="product-detail-rating" >

                                                        </div>
                                                    </div>
                                                    <div className="clearfix product-price-cover">
                                                        <div className="product-price primary-color float-left">
                                                            <ins><span className="text-brand">شكرا لك تم تثبيت طلبك</span></ins>

                                                        </div>
                                                    </div>
                                                    <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                                                    <div className="short-desc mb-30">
                                                        <p>
                                                            سوف يصلك الطلب خلال 24 الى 48 ساعه
                                                        </p>
                                                    </div>


                                                    <div className="bt-1 border-color-1 mt-30 mb-30"></div>

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Link  className="button button-add-to-cart"
                                                            to={'/'}
                                                        >حجز قطعة اخرى</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </main>
            </div>
        );
    }
}

export default Home;
