/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Logo from './img/logo.png';
import {Link } from 'react-router-dom';
class Home extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div className="App">

                <footer className="bg-dark text-center text-white">
                    <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">

                    </section>
                    <section className="">
                        <div className="container text-center text-md-start mt-5">
                            <div className="row mt-3">
                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4" style={{ color: 'white' }}>
                                        تواصل معنا
                                    </h6>
                                    <p style={{ color: 'white', fontSize: 14 }}> العراق بغداد المنصور</p>
                                    <p style={{ color: 'white', fontSize: 14 }}>

                                        info@lamha-iq.com
                                    </p>

                                </div>
                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4" style={{ color: 'white' }}>
                                        رعاية العميل
                                    </h6>
                                    <p style={{ color: 'white', fontSize: 14 }}>
                                        <Link to="/ShippingPolicy" className="text-reset">سياسة التوصيل</Link>
                                    </p>
                                    <p style={{ color: 'white', fontSize: 14 }}>
                                    <Link to="/PrivacyPolicy" className="text-reset">سياسة خاصة</Link>
                                    </p>
                                    <p style={{ color: 'white', fontSize: 14 }}>
                                    <Link to="/TermsOfService" className="text-reset">شروط الخدمة</Link>
                                    </p>
                                    <p style={{ color: 'white', fontSize: 14 }}>
                                    <Link to="/RefundPolicy" className="text-reset">سياسات الاستبدال او الاسترجاع</Link>
                                    </p>
                                </div>
                                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                    <h6 className="text-uppercase fw-bold mb-4" style={{ color: 'white' }}>
                                        <i className="fas fa-gem me-3"></i> عيون المستقبل للتسوق عبر الانترنيت
                                    </h6>
                                    <img src={Logo} alt='img' style={{ width: '200px', height: '200px' }} />
                                </div>
                            </div>
                        </div>
                    </section>
                </footer>
            </div>
        );
    }
}

export default Home;
