/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/logo.png'

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                    <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
                        <center>
                            <img width={75} height={75} src={Logo} alt="" />
                            <h3>Liger.fashion</h3>
                        </center>

                    </div>
                  
                    <div class="col-12 col-lg-12" style={{padding:40}}>
                        <div class="single_product_desc">
                            <div class="product-meta-data" style={{textAlign: 'center'}}>

                                <a href="#">
                                    <h3>سياسة خاصة </h3>
                                </a>
           

                            </div>

                            <div class="short_overview my-5">
                                <div style={{textAlign: 'right'}}>
                                    <h4 style={{marginBottom:20}}>جمع المعلومات الشخصية </h4>
                                    <p style={{textAlign: 'right', fontSize: 20}}>
                                        عندما تزور الموقع ، نقوم بجمع معلومات معينة حول جهازك ، وتفاعلك مع الموقع ،
                                        والمعلومات اللازمة لمعالجة مشترياتك. قد نقوم أيضًا بجمع معلومات إضافية إذا اتصلت
                                        بنا للحصول على دعم العملاء. في سياسة الخصوصية هذه ، نشير إلى أي معلومات يمكنها
                                        تعريف الفرد بشكل فريد (بما في ذلك المعلومات أدناه) على أنها "معلومات شخصية".
                                        انظر القائمة أدناه للحصول على مزيد من المعلومات حول ماهية المعلومات الشخصية التي
                                        نجمعها ولماذا.

                                    </p>

                                </div>
                                <div class="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:20}}>أمثلة على المعلومات الشخصية التي تم جمعها</h4>
                                        <ul class="list-group list-group-flush" style={{textAlign: 'right', fontSize: 20}}>
                                            <li lass="list-group-item"> صدار متصفح الويب</li>
                                            <li lass="list-group-item">المنطقة الزمنية</li>
                                            <li lass="list-group-item">معلومات ملفات تعريف الارتباط </li>
                                            <li lass="list-group-item">المواقع </li>
                                            <li lass="list-group-item"> عنوان IP </li>
                                            <li lass="list-group-item">معلومات الطلب الاسم, رقم الهاتف , العنوان </li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:20}}>اتصل</h4>
                                        <p style={{textAlign: 'right', fontSize: 20}}>
                                            لمزيد من المعلومات حول ممارسات الخصوصية لدينا ، إذا كانت لديك أسئلة ، أو إذا
                                            كنت ترغب في تقديم شكوى ، فيرجى الاتصال بنا عبر البريد الإلكتروني على
                                           
                                            info@lamha-iq.com
                                            <p style={{textAlign: 'right', fontSize: 20}}>
                                             او عن طريق الهاتف
                                              009647822288848</p>
                                        </p>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default Home;
